import {backendGeneratedApi} from './backendGeneratedApi'

export const coreApi = backendGeneratedApi.enhanceEndpoints({
  addTagTypes: ['Companies', 'Company', 'Coupons'],
  endpoints: {
    getWallets: {
      providesTags: ['Companies'],
    },
    updateOrganizationWallet: {
      invalidatesTags: ['Companies'],
    },
    getWallet: {
      providesTags: (result) => (result ? [{type: 'Company', id: result.id}] : []),
    },
    uploadFileToWallet: {
      invalidatesTags: (_, __, arg) => [{type: 'Company', id: arg.walletId}, 'Companies'],
    },
    deleteFileFromWallet: {
      invalidatesTags: (_, __, arg) => [{type: 'Company', id: arg.walletId}, 'Companies'],
    },
    walletPasses: {
      providesTags: (result, err, arg) =>
        result ? [{type: 'Coupons', id: arg.walletId}, 'Coupons'] : [],
    },
    generateQrCodes: {
      invalidatesTags: (result, err, arg) => (result ? [{type: 'Coupons', id: arg.walletId}] : []),
    },
    deleteQrCoupons: {
      invalidatesTags: (result, err, arg) => (!err ? [{type: 'Coupons', id: arg.walletId}] : []),
    },
    deletePass: {
      invalidatesTags: ['Coupons'],
    },
  },
})

export const {
  useGetWalletsQuery,
  useLoginMutation,
  useRegisterCustomerByQrMutation,
  useRegisterCustomerMutation,
  useUpdateOrganizationWalletMutation,
  useSaveOrganizationWalletMutation,
  useGetWalletQuery,
  useUploadFileToWalletMutation,
  useDeleteFileFromWalletMutation,
  useWalletPassesQuery,
  useGenerateQrCodesMutation,
  useExportToExcelMutation,
  useDeleteQrCouponsMutation,
  useDeletePassMutation,
  useGetTokenInfoQuery,
  useResendPassMutation,
  useUploadSalesPersonsMutation,
  useWalletSalesPersonQuery,
  useOrganizationListQuery,
} = coreApi
